<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {formatForMoney} from "@Res/js/helpers";
import Button from "@Components/Button.vue";
import AnchorLink from "@Components/AnchorLink.vue";
import List from "@Res/js/Pages/Storefront/Partials/CartItems/List.vue";

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    }
})
const emit = defineEmits(["close"])
const close = () => emit("close")
</script>

<template>
    <TransitionRoot :show="show" as="template">
        <Dialog as="div" class="relative z-[80]" @close="close">
            <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0"
                             enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100"
                             leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild as="template"
                                         enter="transform transition ease-out duration-500"
                                         enter-from="translate-x-full" enter-to="translate-x-0"
                                         leave="transform transition ease-out duration-500"
                                         leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                    <div class="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                        <div class="flex items-start justify-between">
                                            <DialogTitle class="text-lg font-medium text-gray-900">Cart Items
                                            </DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <Button class="min-w-min p-1.5" fit kind="white" @click="close">
                                                    <span class="sr-only">Close panel</span>
                                                    <svg aria-hidden="true" class="size-4">
                                                        <use href="#icon-xmark"/>
                                                    </svg>
                                                </Button>
                                            </div>
                                        </div>

                                        <div v-if="$page.props?.cart?.items?.length > 0" class="mt-10">
                                            <div class="flow-root">
                                                <ul class="-my-6 divide-y divide-gray-200" role="list">
                                                    <List v-for="item in $page.props?.cart?.items"
                                                          :key="item.uuid" :item="item" class="flex py-6 items-start"/>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else class="flex items-center justify-center py-32">Your cart is empty
                                        </div>
                                    </div>


                                    <div v-if="$page.props?.cart?.items?.length > 0"
                                         class="border-t border-gray-200 px-4 py-6 sm:px-6">
                                        <div class="flex justify-between text-base font-medium text-gray-900">
                                            <p>Subtotal</p>
                                            <p v-text="formatForMoney($page.props?.cart?.subtotal, $page.props.store.currency)"></p>
                                        </div>
                                        <p class="mt-0.5 text-sm text-gray-500">Discounts are calculated at
                                            checkout.</p>
                                        <div class="mt-6">
                                            <AnchorLink
                                                :href="route('storefront.checkout', {'store_slug': $page.props.store.slug})"
                                                kind="primary"
                                                type="button">
                                                Checkout
                                            </AnchorLink>
                                        </div>
                                        <div class="mt-6 flex justify-center text-center text-sm text-gray-500">
                                            <p>
                                                <button class="font-medium text-gray-800 hover:text-gray-700"
                                                        type="button"
                                                        @click="close">
                                                    <span aria-hidden="true">&larr; </span>
                                                    Return to page
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
